import { graphql } from "gatsby";
import Img from "gatsby-image";
import * as React from "react";
import { StructuredText } from "react-datocms";
import Clients from "../components/Clients";
import Layout from "../components/layout";
import SlickSlider from "../components/Slider";
import theme from "../styles/theme";

const AboutPage = ({ location, data }) => {
  return (
    <Layout
      location={location}
      seo={data.page.seoMetaTags}
      logo={data.global.logo}
    >
      <main
        css={`
          margin: 0 auto;
          padding-bottom: 200px;
          width: 100%;
          max-width: 1600px;
          box-sizing: border-box;
          padding-top: 100px;
          ${theme.tablet} {
            padding-top: 100px;
            padding-bottom: 0;
          }
        `}
      >
        <section
          css={`
            flex-direction: column;
            h1 {
              margin-top: 130px;
              font-size: 72px;
              ${theme.laptop} {
                font-size: 58px;
                margin-top: 40px;
              }
              ${theme.mobile} {
                font-size: 36px;
                margin-top: 10px;
              }
            }
          `}
        >
          <h1>{data.page.title}</h1>
          <div
            css={`
              display: grid;
              grid-gap: 40px;
              grid-template-columns: repeat(5, 1fr);
              ${theme.max700} {
                grid-template-columns: repeat(2, 1fr);
              }
              ${theme.max500} {
                grid-template-columns: repeat(1, 1fr);
              }
              .item {
                padding: 20px;
                .imgBox {
                  height: 120px;
                  img {
                    height: 100%;
                  }
                }
                h4 {
                  margin: 20px 0 0;
                  color: ${theme.blue};
                  font-size: 48px;
                  font-weight: 900;
                }
                p {
                  margin-top: 10px;
                  font-size: 22px;
                }
              }
              ${theme.max1400} {
                grid-template-columns: repeat(4, 1fr);
                .item {
                  h4 {
                    font-size: 42px;
                  }
                }
              }
              ${theme.max1100} {
                .item {
                  .imgBox {
                    height: 100px;
                  }
                  h4 {
                    font-size: 36px;
                  }
                }
              }
              ${theme.tablet} {
                grid-template-columns: repeat(3, 1fr);
              }
              ${theme.max700} {
                grid-template-columns: repeat(2, 1fr);
              }
              ${theme.mobile} {
                grid-gap: 20px;
                .item {
                  p {
                    margin-top: 2px;
                  }
                }
              }
              ${theme.mobile} {
                .item {
                  padding: 0;
                  h4 {
                    font-size: 24px;
                  }
                  p {
                    font-size: 18px;
                  }
                }
              }
            `}
          >
            {data.page.numbers.map((item, index) => {
              return (
                <div key={index} className="item">
                  <div className="imgBox">
                    <img
                      src={item.icon.url.split("?").shift()}
                      alt={item.icon.alt}
                    />
                  </div>
                  <h4>{item.title}</h4>
                  <p>{item.content}</p>
                </div>
              );
            })}
          </div>
        </section>
        <section
          css={`
            margin-top: 200px;
            margin-bottom: 200px;
            flex-wrap: wrap;
            ${theme.max1600} {
              padding-left: 0;
              padding-right: 0;
            }
            ${theme.tablet} {
              margin-top: 120px;
              margin-bottom: 120px;
            }
            div:first-child {
              flex: 1;
            }
            .img {
              width: 100%;
              img {
                width: 100%;
                object-fit: cover;
                object-position: 50% 50%;
              }
            }
            .content {
              padding-left: 100px;
              padding-right: 140px;
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
              h2 {
                margin: 0;
              }
              h3 {
                font-weight: 900;
                font-size: 28px;
                margin: 40px 0 0;
              }
              .button {
                margin-top: 20px;
                color: ${theme.pink};
              }
              ${theme.tablet} {
                padding-left: 20px;
              }
              ${theme.laptop} {
                padding-right: 40px;
              }
              ${theme.mobile} {
                padding-right: 20px;
              }
            }

            ${theme.mobile} {
              margin-top: 100px;
              margin-bottom: 100px;
              .content {
                flex-basis: 100%;
                margin-top: 40px;
              }
            }
          `}
        >
          <div>
            <Img
              className="img"
              fluid={data.page.contentImg.fluid}
              alt={data.page.contentImg.alt}
            />
          </div>
          <div className="content">
            <StructuredText data={data.page.content} />
          </div>
        </section>
        <section>
          <h2>{data.page.galleryTitle}</h2>
        </section>
        <SlickSlider data={data.page.gallery} />
        <Clients
          dataTitle={data.page.clientsTitle}
          dataClients={data.clients}
        />
      </main>
    </Layout>
  );
};

export default AboutPage;

export const query = graphql`
  query YonotonQuery {
    page: datoCmsYonoton {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      numbers {
        title
        content
        icon {
          url
          alt
        }
      }
      contentImg {
        fluid(maxWidth: 680) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      content {
        value
      }
      galleryTitle
      gallery {
        fluid(maxWidth: 1600) {
          ...GatsbyDatoCmsFluid
        }
      }
      clientsTitle
    }
    clients: allDatoCmsClient(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          title
          slug
          text
          thumbnail {
            fluid(maxWidth: 512) {
              ...GatsbyDatoCmsFluid
            }
            alt
          }
        }
      }
    }
    global: datoCmsGlobal {
      logo {
        fluid(maxWidth: 700) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
    }
  }
`;
